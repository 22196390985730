import styled from 'styled-components';

import { vwMinMax, position, remCalc, breakpoint, color } from '@/style';

// https://api.mapbox.com/styles/v1/mapbox/streets-v10/static/9.30529,48.74046,16.2,0,0/1280x500@2x?access_token=pk.eyJ1IjoiZGllc2NobXVja2VyZWkiLCJhIjoiY2ptM2JwOHY1MnphbTN3bGl4YmtydmFtZyJ9.FUPIS8sb-YH3FsRaW5OXew

// https://api.mapbox.com/styles/v1/dieschmuckerei/cjpmrt7i61cv82qo9jaffmqj8/static/9.30529,48.74046,16.2,0,0/1280x500@2x?access_token=pk.eyJ1IjoiZGllc2NobXVja2VyZWkiLCJhIjoiY2ptM2JwOHY1MnphbTN3bGl4YmtydmFtZyJ9.FUPIS8sb-YH3FsRaW5OXew

/**
 * Breakpoints für die Komponente Mobile <-> Desktop
 */
const elementBreakpointMiddle = 1000;
const elementBreakpointEnd = 1600;

/**
 * Wrapper für die Komponente
 */
export const Wrapper = styled.div`
    /* ${vwMinMax({ cssProp: `marginTop`, minValue: 80, maxValue: 120 })}; */
    position: relative;
`;

/**
 * InfoBox
 */
export const InfoBox = styled.div`
    ${vwMinMax({
        cssProp: `marginLeft`,
        minValue: 60,
        maxValue: 120,
        minViewport: elementBreakpointEnd,
        maxViewport: breakpoint.xxxlarge,
        noMobileMQ: true
    })};

    ${vwMinMax({
        cssProp: `width`,
        minValue: 390,
        maxValue: 500,
        minViewport: elementBreakpointEnd,
        maxViewport: breakpoint.xxxlarge,
        noMobileMQ: true
    })};

    ${vwMinMax({ cssProp: `padding`, minValue: [20, 15, 18], maxValue: [20, 112, 18] })};

    padding: 1.35em;
    width: 100%;
    background-color: ${color.goldMatte};
    color: ${color.black};
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @media (min-width: ${elementBreakpointMiddle}px) {
        align-items: flex-start;
        flex-direction: row;
        justify-content: center;
        text-align: left;
    }

    @media (min-width: ${elementBreakpointEnd}px) {
        ${position({ bottom: `6.5%`, left: `0` })};
        display: block;
        padding: 1.35em;
        z-index: 1;
    }

    @media (min-width: 1850px) {
        ${position({ top: `-6.5%`, left: `0`, bottom: `auto` })};
    }
`;

/**
 * Item innerhalb der Box
 */
export const InfoItem = styled.div`
    &:not(:last-child) {
        margin-bottom: 0.95em;
        @media (min-width: ${elementBreakpointMiddle}px) {
            margin-bottom: 0;
        }
        @media (min-width: ${elementBreakpointEnd}px) {
            margin-bottom: 0.95em;
        }
        &:after {
            content: '';
            background-color: ${color.black};
            margin: 0.95em auto 0;
            display: block;
            height: ${remCalc(2)};
            width: ${remCalc(95)};

            @media (min-width: ${elementBreakpointMiddle}px) {
                display: none;
            }

            @media (min-width: ${elementBreakpointEnd}px) {
                margin: 0.85em 0;
                display: block;
            }
        }
    }

    @media (min-width: ${elementBreakpointMiddle}px) {
        margin-bottom: 0;
        width: 33.333%;
        &:not(:last-child) {
            margin-right: 2.5em;
        }
        &:nth-child(2) {
            text-align: center;
        }
        &:nth-child(3) {
            text-align: right;
        }
    }

    @media (min-width: ${elementBreakpointEnd}px) {
        width: 100%;
        text-align: left;
        &:nth-child(2),
        &:nth-child(3) {
            text-align: left;
        }
        &:not(:last-child) {
            margin-bottom: 0.95em;
            margin-right: 0;
        }
    }
`;

/**
 * Headline eines InfoItems
 */
export const InfoHeadline = styled.h4`
    ${vwMinMax({ cssProp: `fontSize`, minValue: 20, maxValue: 24 })};
    margin-bottom: 0.25em;
`;

/**
 * Text des InfoItems
 */
export const InfoText = styled.p`
    ${vwMinMax({ cssProp: `fontSize`, minValue: 14, maxValue: 18 })};
    margin-bottom: 0;
`;

/**
 * Link für Telefon / Fax / Mail
 */
export const Link = styled.a`
    color: ${color.black};
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
        text-decoration: underline;
    }
`;

/**
 * Wrapper für die Karte
 */
export const Map = styled.div`
    display: none;
    @media (min-width: ${elementBreakpointEnd}px) {
        display: block;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        padding-top: ${(400 / 1920) * 100}%;
        height: 0;
        max-height: 200px;
        position: relative;
        transtion: opacity 0.5s;
        width: 100%;
    }
`;

/**
 * Copyright Label für die Karte
 */
export const MapCopyright = styled.div`
    ${position({ bottom: `0`, right: `0` })};
    display: flex;
    font-size: ${remCalc(14)};
    padding: 0.5em 0.75em;
    background-color: rgba(255, 255, 255, 0.85);
`;

/**
 * Link für die Karte
 */
export const MapLink = styled.a`
    color: ${color.black};
    display: block;
    text-decoration: none;
    margin-left: 0.5em;
    &:first-of-type {
        margin-left: 0;
    }
`;

/**
 * Wrapper für die Map
 */
export const MapImageWrapper = styled.div`
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
`;

/**
 * Wordmark für Mapbox
 */
export const MapWordmark = styled.img`
    position: absolute;
    bottom: ${remCalc(10)};
    left: ${remCalc(10)};
    max-width: 80px;
`;

/**
 * Image der Karte
 */
// export const MapImage = styled(Img)``;
