/**
 * Farben
 */
export const color = {
    black: `#000000`,
    white: `#ffffff`,
    grayLight: `#eeeeee`,
    grayMedium: `#dfdfdf`,
    grayDark: `#1a1a1a`,

    gold: `#DDAE69`,
    goldLight: `#F7EBD9`,
    goldDark: `#CF9A4E`,
    goldMatte: `#EBCEA5`,

    // state colors
    success: `#3adb76`,
    warning: `#ffae00`,
    alert: `#cc4b37`
};

export type Color = keyof typeof color;

/**
 * Hover Transition Speed
 */
export const hoverSpeed = `0.25s`;

export const fontFamily = {
    base: `Museo Sans, Arial, sans-serif`,
    serif: `EB Garamond, Times New Roman, serif`
};

/**
 * Breakpoints
 */
export const breakpoint = {
    small: 640,
    medium: 1024,
    large: 1200,
    xlarge: 1600,
    xxlarge: 1920,
    xxxlarge: 2560
};

export type Breakpoint = keyof typeof breakpoint;
