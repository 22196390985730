import styled from 'styled-components';

import { mq, remCalc } from '@/style';
import hamburgerSVG from '@/assets/img/navigation/hamburger.svg';
import closerSVG from '@/assets/img/navigation/closer.svg';

export const OffCanvasToggler = styled.div<{ showCloser?: boolean }>`
    @media ${mq.smallOnly} {
        background-image: url(${({ showCloser }) => (showCloser ? closerSVG : hamburgerSVG)});
        background-position: center center;
        background-repeat: no-repeat;
        cursor: pointer;
        display: block;
        flex-shrink: 0;
        height: ${remCalc(27)};
        position: relative;
        width: ${remCalc(35)};
        z-index: 2;
    }

    @media ${mq.medium} {
        display: none;
    }
`;
