import { Link } from 'gatsby';

import logo from '../../../assets/img/logo.svg';

import { Wrapper } from './styled';

/**
 * Logo Komponente Komponente
 */
export const Logo = () => (
    <Wrapper>
        <Link to="/">
            <img src={logo} alt="die Schmuckerei - Eine Goldschmiede im Herzen Esslingens" />
        </Link>
    </Wrapper>
);
