import styled from 'styled-components';

import { vwMinMax } from '@/style';

/**
 * Wrapper für das Logo
 */
export const Wrapper = styled.div`
    ${vwMinMax({ cssProp: `width`, minValue: 190, maxValue: 350 })};
    flex-shrink: 1;
    img {
        margin-bottom: 0;
        max-height: 100%;
        max-width: 100%;
        height: 100%;
        width: 100%;
        flex: 1;
    }
    a {
        display: flex;
    }
    position: relative;
`;
