import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import { breakpoint, color, mq, position, remCalc, vwMinMax } from '@/style';

/**
 * Navigation Wrapper
 */
export const Wrapper = styled.nav<{ offCanvasActive?: boolean }>`
    display: flex;

    transform: translateX(100%);
    -webkit-overflow-scrolling: auto;

    ${vwMinMax({ cssProp: `gap`, minValue: 35, maxValue: 60, minViewport: breakpoint.medium })};

    @media ${mq.smallOnly} {
        ${position({ top: `0`, right: `0` }, `fixed`)};
        flex-direction: column;
        height: 100%;
        height: 100vh;
        background-color: ${color.white};
        ${({ offCanvasActive }) =>
            offCanvasActive &&
            css`
                transform: translateX(0);
                box-shadow: ${remCalc(-3, 0, 3, 0)} ${rgba(color.black, 0.15)};
            `};
        transition: transform 0.5s, box-shadow 0.5s;
        padding: ${remCalc(18, 30, 20, 30)};
        width: ${remCalc(280)};
        overflow-y: auto;
        z-index: 4;
    }

    @media ${mq.medium} {
        align-items: center;
        transform: translateX(0);
    }
`;
