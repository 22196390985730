import instagram from '../../../assets/img/socialicons/instagram-black.svg';

import { Wrapper, Link, Text, Icon } from './styled';

/**
 * Social Bar Komponente
 */
export const SocialBar = () => (
    <Wrapper>
        <Link href="https://www.instagram.com/dieschmuckerei/" target="_blank" rel="noopener noreferrer">
            <Icon src={instagram} alt="Folge uns auf Instagram" />
        </Link>
        <Text>
            <span>FOLGE UNS</span>
        </Text>
    </Wrapper>
);
