import instagramWhite from '../../../assets/img/socialicons/instagram-white.svg';

import { Copyright, LegalLink, LegalLinks, Wrapper, SocialLinks, SocialLink } from './styled';

/**
 * Footer Komponente
 */
export const Footer = () => (
    <Wrapper>
        <Copyright>&copy; die Schmuckerei</Copyright>
        <SocialLinks>
            <SocialLink href="https://www.instagram.com/dieschmuckerei/" target="_blank" rel="noopener noreferrer">
                <img src={instagramWhite} alt="Folge uns auf Instagram" />
            </SocialLink>
        </SocialLinks>
        <LegalLinks>
            <LegalLink to="/impressum">Impressum</LegalLink>
            <div>|</div>
            <LegalLink to="/datenschutz">Datenschutz</LegalLink>
        </LegalLinks>
    </Wrapper>
);
