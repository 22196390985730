import { useRef } from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import { Link } from 'gatsby';

import { getNavLinkProps, scrollToAnchor } from '@/utils';
import { mainRoutes } from '@/resources';

import { OffCanvasToggler } from '../OffCanvasToggler';

import { Wrapper } from './styled';

interface NavigationProps {
    offCanvasActive?: boolean;
    closeOffCanvas: () => void;
}

/**
 * Navigation Komponente
 */
export const Navigation = ({ offCanvasActive = true, closeOffCanvas }: NavigationProps) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    useOnClickOutside(wrapperRef, closeOffCanvas);

    /**
     * Achtung: Styles für "navlink" sind unter "GlobalStyles" definiert
     */
    return (
        <Wrapper ref={wrapperRef} offCanvasActive={offCanvasActive}>
            <OffCanvasToggler showCloser onClick={closeOffCanvas} />
            {mainRoutes.map(({ to, text }) => {
                const key = `main-nav-${text}`;
                return to.charAt(0) === `#` ? (
                    <a
                        key={key}
                        className="navlink"
                        href={to}
                        onClick={evt => {
                            closeOffCanvas();
                            scrollToAnchor(evt);
                        }}
                    >
                        {text}
                    </a>
                ) : (
                    <Link key={key} className="navlink" to={to} getProps={getNavLinkProps} onClick={closeOffCanvas}>
                        {text}
                    </Link>
                );
            })}
        </Wrapper>
    );
};
