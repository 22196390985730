import styled from 'styled-components';

import { color, position, remCalc } from '@/style';

/**
 * Breakpoint, ab wann die Komponente angezeigt wird
 */
const elementBreakpoint = 1440;

/**
 * Wrapper
 */
export const Wrapper = styled.div`
    ${position({ top: `50%`, left: remCalc(10) }, `fixed`)};
    display: none;
    flex-direction: column-reverse;
    align-items: center;
    transform: translateY(-50%);
    z-index: 100;

    @media (min-width: ${elementBreakpoint}px) {
        display: flex;
    }
`;

/**
 * Link
 */
export const Link = styled.a`
    display: block;
    margin-top: ${remCalc(20)};
`;

/**
 * Icon (Insta)
 */
export const Icon = styled.img``;

/**
 * Text in der Socialbar
 */
export const Text = styled.strong`
    display: flex;
    align-items: center;
    font-size: ${remCalc(14)};
    font-weight: bold;
    writing-mode: vertical-rl;
    transform: rotate(180deg);

    > span {
        margin-right: ${remCalc(3)};
    }

    &:before {
        content: '';
        display: block;
        background-color: ${color.goldDark};
        height: ${remCalc(55)};
        margin-bottom: ${remCalc(20)};
        width: ${remCalc(3)};
    }
`;
