import styled from 'styled-components';
import { Link } from 'gatsby';

import { color, fontFamily, position, remCalc, vwMinMax } from '@/style';

import { ELEMENT_BREAKPOINT } from './constants';

/**
 * Wrapper
 */
export const Wrapper = styled.footer`
    ${vwMinMax({ cssProp: `padding`, minValue: [20, 15, 18], maxValue: [20, 112, 18] })};
    background-color: ${color.gold};
    font-family: ${fontFamily.serif};
    display: flex;
    flex-direction: column;
    position: relative;
    color: ${color.white};
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
    width: 100%;

    @media (min-width: ${`${ELEMENT_BREAKPOINT}px`}) {
        flex-direction: row;
    }
`;

/**
 * Gap für Copy und Social
 */
const mobileGap = `
    @media (max-width: ${`${ELEMENT_BREAKPOINT - 1}px`}) {
        margin-bottom: 1.5em;
    }
`;

/**
 * Copyright
 */
export const Copyright = styled.div`
    text-align: center;
    ${mobileGap};
    ${vwMinMax({ cssProp: `fontSize`, minValue: 16, maxValue: 24 })};
`;

/**
 * Social-Links
 */
export const SocialLinks = styled.div`
    display: flex;
    ${mobileGap};

    @media (min-width: ${`${ELEMENT_BREAKPOINT}px`}) {
        ${position({ top: `50%`, left: `50%` })};
        align-items: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -30%);
    }
`;

/**
 * Social-Link
 */
export const SocialLink = styled.a`
    display: block;
`;

/**
 * Container für die rechtlichen links
 */
export const LegalLinks = styled.div`
    display: flex;
    gap: ${remCalc(10)};
`;

/**
 * Rechtliche Links
 */
export const LegalLink = styled(Link)`
    ${vwMinMax({ cssProp: `fontSize`, minValue: 16, maxValue: 24 })};
    color: ${color.white};
    text-decoration: none;
`;
