import { StaticImage } from 'gatsby-plugin-image';

import mapboxWordmark from '../../../assets/img/map/mapbox-wordmark.svg';

import {
    InfoBox,
    InfoHeadline,
    InfoItem,
    InfoText,
    Link,
    Map,
    MapCopyright,
    MapImageWrapper,
    MapLink,
    MapWordmark,
    Wrapper
} from './styled';

/**
 * PreFooter
 */
export const PreFooter = () => (
    <Wrapper id="kontakt" className="prefooter">
        <InfoBox>
            <InfoItem>
                <InfoHeadline>Adresse</InfoHeadline>
                <InfoText>
                    die Schmuckerei <br /> Roßmarkt 5 <br /> 73728 Esslingen
                </InfoText>
            </InfoItem>
            <InfoItem>
                <InfoHeadline>Kontakt</InfoHeadline>
                <InfoText>
                    Tel.: <Link href="tel: +49 (0) 711 | 469 900 90"> +49 (0) 711 | 469 900 90</Link>
                    <br />
                    <Link href="mailto:info@dieschmuckerei.de">info@dieschmuckerei.de</Link>
                </InfoText>
            </InfoItem>
            <InfoItem>
                <InfoHeadline>Öffnungszeiten</InfoHeadline>
                <InfoText>
                    Di. – Fr. 10:00-13:00 Uhr & 14:00-19:00 Uhr <br />
                    Sa. 10:00-16:00 Uhr
                </InfoText>
            </InfoItem>
        </InfoBox>
        <Map>
            <MapImageWrapper>
                {/* <MapImage fluid={mapImage.childImageSharp.fluid} alt="Karte mit markiertem Standort der Schmuckerei in Esslingen" /> */}

                <StaticImage alt="" src="../../../assets/img/map/static-map.png" width={2520} />
            </MapImageWrapper>
            <a href="http://www.mapbox.com" target="_blank" rel="noopener noreferrer">
                <MapWordmark src={mapboxWordmark} />
            </a>
            <MapCopyright>
                <MapLink href="https://www.mapbox.com/about/maps/">&copy; Mapbox</MapLink>
                <MapLink href="http://www.openstreetmap.org/copyright">&copy; OpenStreetMap</MapLink>
                <MapLink href="https://www.mapbox.com/map-feedback/" target="_blank" rel="noopener noreferrer">
                    <strong>Improve this map</strong>
                </MapLink>
            </MapCopyright>
        </Map>
    </Wrapper>
);
