import { ReactNode } from 'react';
import CookieConsent from 'react-cookie-consent';
import { Link } from 'gatsby';

import { color, fontFamily, GlobalStyle } from '@/style';
import { Header, ScrollTop, Footer, SocialBar, PreFooter } from '@/components/scaffold';

import { Main } from './styled';

const Layout = ({ children, hideSocialBar }: { children: ReactNode; hideSocialBar?: boolean }) => (
    <>
        <GlobalStyle />
        <Header id="top" />
        {!hideSocialBar && <SocialBar />}
        <ScrollTop />
        <Main>{children}</Main>
        <CookieConsent
            acceptOnScroll
            debug={false}
            buttonText="Verstanden"
            expires={90}
            style={{ alignItems: `center` }}
            contentStyle={{ lineHeight: `1.5em` }}
            buttonStyle={{
                backgroundColor: `transparent`,
                border: `2px solid ${color.white}`,
                color: color.white,
                fontFamily: fontFamily.serif,
                cursor: `pointer`
            }}
        >
            Wir verwenden ausgewählte Cookies um unsere Website stetig für Sie zu verbessern. Durch Nutzung derselben erklären Sie sich
            damit einverstanden.{` `}
            <small>
                <Link to="/datenschutz" className="consentlink">
                    Mehr dazu
                </Link>
            </small>
        </CookieConsent>
        <PreFooter />
        <Footer />
    </>
);

export default Layout;
