import { Script } from 'gatsby';

import { GA_DISABLE_STR } from '@/resources';

interface BaseHeadProps {
    title: string;
    metaDescription?: string;
}

/**
 * Diverse Social-Settings
 */
const socialTitle = `die Schmuckerei`;
const socialDescription = `die Schmuckerei ist eine Goldschmiedewerkstatt im Herzen von Esslingen, die von Anna von Hobe und Adriana Héber gegründet wurde.`;
const url = `https://www.dieschmuckerei.de`;

/**
 * Header, Meta-Tags etc. für die Website
 */
export const BaseHead = ({ title: titleProp, metaDescription = socialDescription }: BaseHeadProps) => {
    const title = `${titleProp} – die Schmuckerei`;
    return (
        <>
            <title>{title}</title>
            <meta charSet="UTF-8" />
            <meta lang="de" />
            <meta name="description" content={metaDescription} />
            <meta
                name="keywords"
                content="die Schmuckerei, Schmuck, Gold, Goldschmiede, Esslingen, Trauringe, Anfertigungen, Kurse, Adriana Héber, Anna von Hobe, Ringe, Handwerk, Goldschmiedekunst"
            />
            <meta property="og:title" content={socialTitle} />
            <meta property="og:url" content={url} />
            <meta property="og:type" content="website" />
            <meta property="og:description" content={socialDescription} />
            <meta property="og:image" content={`${url}/company-image.jpg`} />
            <meta property="og:image:alt" content="Logo der Schmuckerei" />
            <meta property="og:image:height" content="700" />
            <meta property="og:image:width" content="1350" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={socialTitle} />
            <meta name="twitter:url" content={url} />
            <meta name="twitter:description" content={socialDescription} />
            <meta name="twitter:image" content={`${url}/company-image.jpg`} />
            <meta name="twitter:image:alt" content="Logo der Schmuckerei" />
            <script type="application/ld+json">
                {`
                        {
                            "@context": "http://schema.org",
                            "@type": "JewelryStore",
                            "name": "die Schmuckerei",
                            "description": "${socialDescription}",
                            "image": "${url}/company-image.jpg",
                            "logo": "${url}/logo.png",
                            "url": "${url}",
                            "telephone": "+49 (0) 711 469 900 90",
                            "email": "info@dieschmuckerei.de",
                            "priceRange": "$$",
                            "geo":{
                                "@type": "GeoCoordinates",
                                "latitude": 48.74045,
                                "longitude": 9.30585
                            },
                            "sameAs": ["https://www.instagram.com/dieschmuckerei/"],
                            "address": {
                                "@type": "PostalAddress",
                                "streetAddress": "Roßmarkt 5",
                                "addressLocality": "Esslingen",
                                "postalCode": "73728",
                                "addressCountry": "Germany"
                            },
                            "openingHoursSpecification": [
                                {
                                    "@type": "OpeningHoursSpecification",
                                    "dayOfWeek": [
                                    "Tuesday",
                                    "Wednesday",
                                    "Thursday",
                                    "Friday"
                                    ],
                                    "opens": "10:00",
                                    "closes": "13:00"
                                },
                                {
                                    "@type": "OpeningHoursSpecification",
                                    "dayOfWeek": [
                                        "Tuesday",
                                        "Wednesday",
                                        "Thursday",
                                        "Friday"
                                    ],
                                    "opens": "14:00",
                                    "closes": "19:00"
                                    },
                                {
                                    "@type": "OpeningHoursSpecification",
                                    "dayOfWeek": [
                                    "Saturday",
                                    "Sunday"
                                    ],
                                    "opens": "10:00",
                                    "closes": "16:00"
                                }
                                ]
                        }
                `}
            </script>
            <Script>
                {`
                    if (document.cookie.indexOf('${GA_DISABLE_STR}' + '=true') > -1) {
                        window[${GA_DISABLE_STR}] = true;
                    }
                `}
            </Script>
        </>
    );
};
