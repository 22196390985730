import { css } from 'styled-components';

import { Gap } from '@/types';

import { vwMinMax } from './vwMinMax';

/**
 * Fügt einen responsiven, viewportabhängigen Abstand hinzu
 * @param defaultGap Standard-Gap === Standard-Abstand eines Elements, der mit der Gap überschrieben werden kann
 * @returns Gewrapptes CSS
 */
export const gapable =
    (defaultGap?: Gap) =>
    ({ gap }: { gap?: Gap }) => {
        // Kein Abstand
        if (gap === `none`) {
            return `margin-bottom: 0 !important`;
        }

        // "Einfacher Abstand"
        if (typeof gap === `number` || typeof gap === `string`) {
            return `margin-bottom: ${gap}`;
        }

        // "Standard"-Abstand (responsive)
        if (typeof gap === `boolean` || typeof defaultGap === `boolean`) {
            return css`
                ${vwMinMax({
                    cssProp: `marginBottom`,
                    minValue: 20,
                    maxValue: 40,
                    usePixel: true
                })}
            `;
        }

        // Responsiver Abstand mit definierten Werten
        if ((gap && gap.length) || (defaultGap && typeof defaultGap === `object`)) {
            return css`
                ${vwMinMax({
                    cssProp: `marginBottom`,
                    minValue:
                        gap && gap.length
                            ? (gap[0] as number)
                            : typeof defaultGap === `object` && defaultGap
                            ? (defaultGap[0] as number)
                            : 0,
                    maxValue:
                        gap && gap.length
                            ? (gap[1] as number)
                            : typeof defaultGap === `object` && defaultGap
                            ? (defaultGap[1] as number)
                            : 0,
                    usePixel: true
                })}
            `;
        }

        if (typeof defaultGap === `number` || typeof defaultGap === `string`) {
            return `margin-bottom: ${defaultGap}`;
        }

        return null;
    };
